<template>
  <div style="padding-top:46px;">
    <div>
      <van-form ref="forms">
        <van-field readonly
                   clickable
                   name="picker"
                   :value="form.holidayName"
                   :label="$t('tbm.休假类型')"
                   :disabled="authEdit"
                   :required="!authEdit"
                   is-link
                   input-align="right"
                   :placeholder="$t('tbm.请选择休假类型')"
                   @click="showHolidayTypePicker = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择休假类型'),trigger: 'change' }]" />
        <van-popup v-model="showHolidayTypePicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="dayTypeDicList"
                      @confirm="onTemplateConfirm"
                      @cancel="showHolidayTypePicker = false" />
        </van-popup>
        <!-- <div style="color: #4378BE;line-height:40px;padding-left:10px;">{{ $t('tbm.请假注意事项') }}</div> -->
        <van-field readonly
                   clickable
                   :disabled="authEdit"
                   :required="!authEdit"
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.beginDate"
                   :label="$t('tbm.开始时间')"
                   :placeholder="$t('tbm.请选择开始时间')"
                   @click="showBeginDate = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择开始时间'),trigger: 'change'}]" />

        <van-popup v-model="showBeginDate"
                   v-if="showBeginDate"
                   position="bottom"
                   round
                   :style="{ height: '30%' }">
          <van-datetime-picker v-model="beginDate"
                               type="datetime"
                               @confirm="beginDateChange"
                               @cancel="showBeginDate=false"
                               :title="$t('tbm.选择开始时间')" />
        </van-popup>

        <van-field readonly
                   clickable
                   :required="!authEdit"
                   :disabled="authEdit"
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.endDate"
                   :label="$t('tbm.结束时间')"
                   :placeholder="$t('tbm.请选择结束时间')"
                   @click="showEndDate = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择结束时间'),trigger: 'change'}]" />
        <van-popup v-model="showEndDate"
                   v-if="showEndDate"
                   position="bottom"
                   round
                   :style="{ height: '30%' }">
          <van-datetime-picker v-model="endDate"
                               type="datetime"
                               @confirm="endDateChange"
                               @cancel="showEndDate=false"
                               :title="$t('tbm.选择结束时间')" />
        </van-popup>
        <van-field readonly
                   clickable
                   :value="form.totalDays"
                   :label="$t('tbm.折合天数')"
                   :required="!authEdit"
                   :disabled="authEdit"
                   is-link
                   input-align="right"
                   placeholder=""
                   :rules="[{ required: true, message: this.$t('tbm.折合天数不能为空'),trigger: 'change'}]" />
        <van-field v-model="form.reason"
                   style="margin-top:10px;"
                   rows="3"
                   :disabled="authEdit"
                   autosize
                   :label="$t('tbm.请假原因')"
                   type="textarea"
                   :placeholder="$t('tbm.请输入请假原因')"
                   :required="!authEdit"
                   :rules="[{ required: true, message: this.$t('tbm.请假原因不能为空'),trigger: 'change'}]" />
      </van-form>
    </div>
    <approvalButton ref="approvalButton"
                    v-if="form && !authEdit"
                    saveUrl="applyHoliday"
                    v-model="procConf.woCode"
                    :taskInstanceId="procConf.taskInstanceId"
                    :processName="procConf.processInstanceName"
                    :form="form"
                    :validForm="validateForm"
                    :submitVisable1="submitVisable1"></approvalButton>
  </div>
</template>
<script>
import approvalButton from '@/views/business/components/approvalButton';
import ehrUtils from '@/views/business/libs/EhrUtils.js';
export default {
  components: {
    approvalButton,
    ehrUtils
  },
  props: {
    changeForm: {
      type: Object,
      default: function () {
        return {}
      }
    },
    processName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showHolidayTypePicker: false,
      showCalendar: false,
      showBeginDate: false,
      showEndDate: false,
      form: {
        id: '',
        pkOrg: '',
        pkDept: '',
        pkUser: '',
        holidayName: '',
        holidayType: '',
        beginDate: '',
        endDate: '',
        totalDays: '',
        formStatus: '0'
      },
      beginDate: new Date(),
      endDate: new Date(),
      dayTypeDicList: [],
      deptAndPost: '',
      procConf: {
        businessKey: '',
        processInstanceName: this.processName || '请假申请',
        woCode: 'TbmapplyHoliday', //对应不同的请假类型
        taskInstanceId: '',
        variables: {}, //用于流程条件判断
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
        activityCode: ''
      },
      flag: false,
      isApply: '0',  //是否提前申请 默认否
      delayDays: 0,   //延迟天数
      mayApply: '0.0', // 可申请假期
      defaultAttentions: this.TbmCons.DefalutAttentions.defalutMessage,
      submitVisable1: false,
      authEdit: false,
      isLimitWeekend: '2', // 连续内是否包含周末 1-包含 2-不包含
      isIOS: false,
      totalHours: 0 //请假总小时数
    }
  },
  watch: {
    changeForm: {
      handler (newVal, oldVal) {
        if (newVal && newVal.pkHolidayChange) {
          this.form = newVal;
          if (this.form.formStatus === '0') {
            this.authEdit = false;
          } else {
            this.authEdit = true
          }
        }
      },
      deep: true
    }
  },
  methods: {
    //根据请假类型查询是否提前申请,回填注意事项
    getAdvanceApply (flag) {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyHoliday/getHolidayByType',
        params: {
          holidayType: flag,
          pkOrg: this.form.pkOrg,
          year: new Date().getFullYear()
        }
      }).then((res) => {
        //设置注意事项
        let resData = res.data.ext.holidayDetail
        let annualLeaveData = res.data.ext.annualLeave
        if (resData) {
          this.isLimitWeekend = resData.isLimitWeekend; // 连续日期内是否包含周末
          let alreadyUsed = '0.0' //已经使用假期
          let applyProcess = '0.0' // 申请过程的假期
          // this.mayApply ='0.0' //可以申请的假期
          let minTime = ehrUtils.isEmpty(resData.minTime) ? '' : '请假最短时长' + resData.minTime          //请假最短时长
          let limiteduration = ehrUtils.isEmpty(resData.limitDays) ? '' : '一年内限制时长' + resData.limitDays//限制时长
          let minUnit = (resData.minUnit === '1') ? '天' : '小时'
          let controlTimeunit = resData.controlTimeunit === '' ? minUnit : (resData.controlTimeunit === '1') ? '天' : '小时'
          let unnualAttentions = ''
          this.initDefalutAttentions()
          if (annualLeaveData) {
            //年休假
            if (resData.holidayType === this.TbmCons.holidayType.HOLIDAY_TYPE_ANNUAL) {
              alreadyUsed = annualLeaveData.usedLeavedays
              applyProcess = annualLeaveData.auditingLeavedays
              this.mayApply = annualLeaveData.laveLeavedays
              unnualAttentions = ' 1.已经使用的年假为' + alreadyUsed + '天,申请过程中的' + applyProcess + '天,可申请' + this.mayApply + '天</br>'
              this.defaultAttentions = unnualAttentions + this.defaultAttentions
              //调休假
            } else if (resData.holidayType === this.TbmCons.holidayType.HOLIDAY_TYPE_ADJUST) {
              alreadyUsed = annualLeaveData.usedOver
              applyProcess = annualLeaveData.auditingOver
              this.mayApply = annualLeaveData.laveOver
              unnualAttentions = ' 1.已经使用的调休假为' + alreadyUsed + '天,申请过程中的' + applyProcess + '天,可申请' + this.mayApply + '天</br>'
              this.defaultAttentions = unnualAttentions + this.defaultAttentions
            }
          } else {
            //针对其他所有假期
            if (minTime !== '') {
              unnualAttentions = minTime + minUnit + unnualAttentions + '</br>'
            }
            if (limiteduration !== '') {
              unnualAttentions = unnualAttentions + limiteduration + controlTimeunit + '</br>'
            }
            this.defaultAttentions = unnualAttentions + this.defaultAttentions
          }
          if (resData.isApply) {
            this.isApply = resData.isApply;
            this.delayDays = resData.delayDays;
          } else {
            this.isApply = '0'
            this.delayDays = 0
          }
        } else {
          this.initDefalutAttentions()
        }

        //验证年休假 调休假是否设置
        if (!res.data.ext.hasSeting) {
          this.hasSeting = true
          this.$notify({ type: 'danger', message: '您的信息没有在申请年份休假中设置，请联系考勤管理员设置' });
          this.form.totalDays = '';
          return false;
        }
        this.getApplyWoCode(this.form.holidayType);
      })
    },
    initDefalutAttentions () {
      this.defaultAttentions = this.TbmCons.DefalutAttentions.defalutMessage
    },
    getApplyWoCode (type) {
      //先处理特殊用户
      if (this.deptAndPost === this.TbmCons.deptAndPost.CEO || this.deptAndPost === this.TbmCons.deptAndPost.CEOSENCOND || this.deptAndPost === this.TbmCons.deptAndPost.CHAIRMANSENCODE) {
        this.procConf.woCode = this.TbmCons.leaveType.GroupLeaderLeave
        return false
      }
      else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_MATERNITY || type === this.TbmCons.holidayType.HOLIDAY_TYPE_PATERNITY) {
        // 生育陪产
        this.procConf.woCode = this.TbmCons.leaveType.MaternityLeave;
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_WEDDING || type === this.TbmCons.holidayType.HOLIDAY_TYPE_FUNERAL || type === this.TbmCons.holidayType.HOLIDAY_TYPE_FAMILY) {
        // 婚 丧 探亲假
        this.procConf.woCode = this.TbmCons.leaveType.FuneralLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_ANNUAL || type === this.TbmCons.holidayType.HOLIDAY_TYPE_PUBLIC) {
        // 公假 年休假
        this.procConf.woCode = this.TbmCons.leaveType.AnnualLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_LEAVE || type === this.TbmCons.holidayType.HOLIDAY_TYPE_SICK) {
        //事假 病假
        this.procConf.woCode = this.TbmCons.leaveType.SickLeave
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_INJURY) {
        //工伤假
        // this.procConf.woCode= this.TbmCons.leaveType.InjuryLeave
        this.procConf.woCode = 'TbmInjuryLeave'
      } else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_ADJUST) {
        //调休假
        this.procConf.woCode = this.TbmCons.leaveType.VacationLeave
      }
      else if (type === this.TbmCons.holidayType.HOLIDAY_TYPE_CHAPERONE) {
        //陪护假
        this.procConf.woCode = this.TbmCons.leaveType.ChaperoneLeave
      }
    },
    submitForm (resolve) {
      this.$refs.forms.validate().then(() => {
        let flag = true;
        delete this.form.formStatusName;
        delete this.form.formColor;
        //验证是否提前申请
        if (this.isApply === '0') {
          if (ehrUtils.judgeDate(this.form.beginDate) > 0) {
            this.$notify({ type: 'danger', message: this.$t('tbm.请假开始日期必须大于当日') });
            flag = false;
          }
        } else {
          //请假结束日期+假期设置中可延迟天数（不含节假日） 必须大于当日
          this.checkCycleSealedOrHolidayDelayDays().then((resultDelayDays) => {
            let newDates = ehrUtils.subtractDate(new Date(this.form.endDate), 'day', resultDelayDays.delayDays)
            newDates = ehrUtils.dateToString(newDates)
            if (ehrUtils.judgeDate(newDates) > 0) {
              this.$notify({ type: 'danger', message: '请假结束日期+假期设置中可延迟天数 必须大于当日' });
              flag = false;
            }
            this.isApply = '0'
          })

        }
        //验证调休年假可用天数
        if (this.form.holidayType === this.TbmCons.holidayType.HOLIDAY_TYPE_ADJUST || this.form.holidayType === this.TbmCons.holidayType.HOLIDAY_TYPE_ANNUAL) {
          if (parseFloat(this.form.totalDays) > parseFloat(this.mayApply)) {
            this.$notify({ type: 'danger', message: this.$t('tbm.可用假期不足') });
            flag = false;
          }
        }
        if (this.hasSeting) {
          this.$notify({ type: 'danger', message: '您的信息没有在申请年份休假中设置，请联系考勤管理员设置后再进行提交申请' });
          flag = false;
        }
        //校验考勤周期是否封存或确认
        this.checkCycleSealedOrConfirm(this.form.beginDate, this.form.endDate).then((resultCycleSealedOrConfirm) => {
          if (resultCycleSealedOrConfirm.flag) {
            this.$notify({ type: 'danger', message: resultCycleSealedOrConfirm.msg });
            flag = false;
          }
        })

        //校验申请的假期是否小于最小申请时长、累计是否已超过一年内最大限制、累计申请是否超过最大次数、本次申请是否大于单次最大数量
        this.checkApplyHolidayeEceed().then((resultApplyHolidayeEceed) => {
          if (resultApplyHolidayeEceed.flag) {
            this.$notify({ type: 'danger', message: resultApplyHolidayeEceed.msg });
            flag = false;
          }
        })
        setTimeout(() => {
          resolve(flag);
        }, 1000)
      });
    },
    async checkCycleSealedOrHolidayDelayDays () {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/applyHoliday/checkCycleSealedOrHolidayDelayDays',
          data: {
            'entity': this.ruleForm
          }
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async checkCycleSealedOrConfirm (beginDate, endDate) {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/attendanceRegist/checkCycleSealedOrConfirm?startDate=' + beginDate + '&endDate=' + endDate,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async checkApplyHolidayeEceed () {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/applyHoliday/checkApplyHolidayeEceed',
          data: {
            'entity': this.form
          },
          params: {
            totalHours: this.totalHours
          }
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async checkApplyHolidayeConfirm (beginDate, endDate) {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/applyHoliday/checkApplyHolidayeConfirm?startDate=' + beginDate + '&endDate=' + endDate,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async validateForm () {
      let activitiData = {
        deptAndPost: '',
        leaveDays: this.form.totalDays,
        leaveType: this.form.holidayType,
        customDeptCode: this.form.customDeptCode
      }
      sessionStorage.setItem('activitiData', JSON.stringify(activitiData))
      let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
      let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
      let officeoutReason=this.form.reason || '';
      if(officeoutReason.length>5){
        officeoutReason=officeoutReason.substring(0.,5)+'……'
      }
      if(this.processName) {
        this.procConf.processInstanceName ='请休假变更'+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.form.totalDays+'天'
      } else {
        this.procConf.processInstanceName ='请休假申请'+'-'+deptName+' '+userName+'-'+officeoutReason+'-'+this.form.totalDays+'天'
      }
      this.getApplyWoCode();
      return new Promise((resolve, reject) => {
        this.submitForm(resolve);
      })
    },
    overlayClick () {
      this.showBeginDate = false;
      this.showEndDate = false;
    },
    beginDateChange (value) {
      let date = new Date(this.dateChange(value,true));
      if (this.form.endDate && date > this.endDate) {
        this.$notify({ type: 'danger', message: this.$t('tbm.开始日期应小于结束日期') });
        let initBeginDate=this.form.beginDate
        if(this.isIOS){
          this.beginDate = new Date(initBeginDate.replace(/-/g, '/'))
        }else{
          this.beginDate = new Date(initBeginDate)
        }
       return false;
      } else {
        this.beginDate = new Date(this.dateChange(value,true));
        this.form.beginDate = this.dateChange(value,false);
        this.showBeginDate = false;
        if (this.form.beginDate && this.form.endDate) {
          this.setDays();
        }
      }

    },
    endDateChange (value) {
      let date = new Date(this.dateChange(value,true));
      if (this.form.beginDate && date < this.beginDate) {
        this.$notify({ type: 'danger', message: this.$t('tbm.开始日期应小于结束日期') });
        let initEndDate=this.form.endDate;
        if(this.isIOS){
          this.endDate=new Date(initEndDate.replace(/-/g, '/'))
        }else{
          this.endDate=new Date(initEndDate)
        }
        return false;
      } else {
        this.endDate = new Date(this.dateChange(value,true));
        this.form.endDate = this.dateChange(value,false);
        this.showEndDate = false;
        if (this.form.beginDate && this.form.endDate) {
          this.setDays();
        }
      }
    },
    dateChange (value,flag) {
      let year = new Date(value).getFullYear();
      let month = (new Date(value).getMonth() + 1) < 10 ? '0'+(new Date(value).getMonth() + 1) :(new Date(value).getMonth() + 1);
      let date = new Date(value).getDate() < 10 ? '0'+new Date(value).getDate() :new Date(value).getDate();
      let hour = new Date(value).getHours() < 10 ? '0' + new Date(value).getHours() :new Date(value).getHours();
      let minutes = new Date(value).getMinutes() < 10 ? '0' + new Date(value).getMinutes() :new Date(value).getMinutes();
      let seconds = new Date(value).getSeconds() <10 ? '0'+new Date(value).getSeconds()  :new Date(value).getSeconds() ;
      if(flag && this.isIOS){
        return year + '/' + month + '/' + date + ' ' + hour + ':' + minutes + ':' + seconds;
      }else{
        return year + '-' + month + '-' + date + ' ' + hour + ':' + minutes + ':' + seconds;
      }




    },
    onTemplateConfirm (value) {
      this.form.holidayName = value.text;
      this.form.holidayType = value.value;
      this.showHolidayTypePicker = false;
      this.getAdvanceApply(this.form.holidayType);
    },
    getHolidayType () {
      this.$myHttp({
        method: 'POST',
        params: {
          pkOrg: this.form.pkOrg
        },
        url: '/hrtbm/holidayDetail/getValidHolidayDetail',
      }).then((res) => {
        this.dayTypeDicList = [];
        if (res.data.length > 0) {
          res.data.map((item) => {
            this.dayTypeDicList.push({
              text: item.name,
              value: item.code
            });
          })
        }
      })
    },
    backFillEditData (flag) {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyHoliday/view',
        data: {
          'entity': {
            'id': this.form.id
          }
        }
      }).then(async res => {
        let backData = res.data.entity;
        if (backData) {
          this.form = backData;
          sessionStorage.setItem('formData', JSON.stringify(this.form));
          if(this.isIOS){
            let endDate=backData.endDate.replace(/-/g, '/')
            let beginDate=backData.beginDate.replace(/-/g, '/')
            this.endDate=new Date(endDate);
            this.beginDate=new Date(beginDate);
          }else{
            this.endDate = new Date(backData.endDate);
            this.beginDate = new Date(backData.beginDate);
          }

        }
        this.deptAndPost = parseInt(res.data.ext.deptAndPost)
        this.getHolidayType();
        this.getAdvanceApply(this.form.holidayType);
        if (this.form.formStatus === '0') {
          this.authEdit = false;
        } else {
          this.authEdit = true
        }
        if (this.$route.query.type) {
          this.authEdit = false;
        }
      });
    },
    async setDays () {
      var startTime = this.form.beginDate;
      var endTime = this.form.endDate;
      this.totalHours=0
      if(this.isIOS){
        startTime = new Date(startTime.replace(/-/g, '/'));
        endTime = new Date(endTime.replace(/-/g, '/'));
      }else{
        startTime = new Date(startTime);
        endTime = new Date(endTime);
      }
      var tableBeginTime = (startTime.getHours() < 10 ? ('0' + startTime.getHours()) : startTime.getHours()) + ':' + (startTime.getMinutes() < 10 ? ('0' + startTime.getMinutes()) : startTime.getMinutes());
      var tableEndTime = endTime.getHours() + ':' + (endTime.getMinutes() < 10 ? ('0' + endTime.getMinutes()) : endTime.getMinutes());
      startTime = startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate() + ' ' + startTime.getHours() + ':' + startTime.getMinutes();
      endTime = endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate() + ' ' + endTime.getHours() + ':' + endTime.getMinutes();
      var dateResult = this.getAllDate(startTime.split(' ')[0], endTime.split(' ')[0]);
      this.form.applyHolidayDetails = [];
       const result = await this.getWorkHourRange(this.form.beginDate, this.form.endDate);
      //	const result = await this.getWorkHourRange(dateResult[0], dateResult[dateResult.length - 1]);
      // true 这说明有重复日期申请
      if (result.data.ext.hasRepeats) {
        this.$notify({ type: 'danger', message: '申请请假 出现重复' });
        this.form.totalDays = '';
        return false;
      }
      //请假日期对应的周期已经封存 不能请假
      if (result.data.ext.isSealUp) {
        this.$notify({ type: 'danger', message: '请假日期 对应的周期已封存' });
        this.form.totalDays = '';
        return false;
      }
      var totalDay = 0.0;
      if (result.data.ext.workhouRangerlist.length >= 0) {
        var hourRangeData = result.data.ext.workhouRangerlist;
        for (var i = 0; i < dateResult.length; i++) {
          var obj = {};
          obj.restDate = dateResult[i];
          //获取请假日期对应的 班时区间
          var hourRangeData1 = this.filterByDay(hourRangeData, obj.restDate)
          if (dateResult.length === 1) {
            obj.rangeFlag = '1';
            obj.rangeName = '自定义';
            obj.beginTime = tableBeginTime;
            obj.endTime = tableEndTime;
            obj.realDays = await this.getRealDays(tableBeginTime, tableEndTime, hourRangeData1, '1');//请假结束 是同一天
          } else {
            if (i === 0) {
              obj.rangeFlag = 'U';
              obj.rangeName = '自定义';
              obj.beginTime = tableBeginTime;
              obj.endTime = '--';
              obj.realDays = await this.getRealDaysConstart(tableBeginTime, '', hourRangeData1);
            } else if (i === (dateResult.length - 1)) {
              obj.beginTime = '--'
              obj.endTime = tableEndTime
              obj.rangeFlag = 'U';
              obj.rangeName = '自定义';
              obj.realDays = await this.getRealDaysConstEnd('', tableEndTime, hourRangeData1);
            } else {
              obj.rangeFlag = 'F';
              obj.rangeName = '全天';
              obj.beginTime = '--';
              obj.endTime = '--';
              obj.realDays = await this.getRealDaysNotCont('', '', hourRangeData1);
            }
          }
          var flag = false;
          for (var j = 0; j < hourRangeData.length; j++) {
            if (hourRangeData[j].DATE_DETAIL.startsWith(obj.restDate)) {
              flag = true
            }
          }

          if (!flag) {
            if (this.isLimitWeekend == '2') {
              obj.realDays = 0.0;
            } else {
              obj.realDays = '1.0';
            }
          }

          // if (!flag) {
          //   obj.realDays = 0.0
          // }
          totalDay += parseFloat(obj.realDays)
          this.form.totalDays = totalDay.toFixed(1)
          this.form.applyHolidayDetails.push(obj);

        }
        //校验申请的假期是否小于最小申请时长
        if ('' === this.form.holidayType) {
          this.$notify({ type: 'danger', message: this.$t('tbm.请先选择休假类型') });
        } else {
          let resultApplyHolidayeEceed = await this.checkApplyHolidayeEceed();
          if (resultApplyHolidayeEceed.flag) {
            this.$notify({ type: 'danger', message: resultApplyHolidayeEceed.msg });
            return false
          }
        }
        if (totalDay == '0.0') {
          this.$notify({ type: 'danger', message: '请假日期不在上班时间范围内，无需请假！！' });
          this.form.applyHolidayDetails = [];
          this.form.totalDays = '';
          return false;
        }
        else {
          this.form.totalDays = totalDay.toFixed(1);
        }

      }
    },
    filterByDay (data, day) {
      return data.filter(item => item.DATE_DETAIL.startsWith(day))
    },
    getAllDate (start, end) {
      let dateArr = []
      let startArr = start.split('-')
      let endArr = end.split('-')
      let db,de
      let nowDate=ehrUtils.getNowDate();
       if(this.isIOS){
        nowDate =nowDate.replace(/-/g, '/')
      }
      db = new Date(nowDate)
      de = new Date(nowDate)
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2])
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2])

      let unixDb = db.getTime()
      let unixDe = de.getTime()
      let stamp
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp = unixDb; stamp <= unixDe;) {
        dateArr.push(this.format(new Date(parseInt(stamp))))
        stamp = stamp + oneDay;
      }
      return dateArr;
    },
    format (time) {
      let ymd = ''
      let mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : ('0' + (time.getMonth() + 1))
      let day = time.getDate() >= 10 ? time.getDate() : ('0' + time.getDate())
      ymd += time.getFullYear() + '-'; // 获取年份。
      ymd += mouth + '-'; // 获取月份。
      ymd += day; // 获取日。
      return ymd; // 返回日期。
    },
    getWorkHourRange (tableBeginTime, tableEndTime) {
      // 增加判断，防止页面报错
      var isReturn = false;
      let apporval=this.$refs['approvalButton'].$refs['approval']
      if (apporval != undefined) {
        isReturn = apporval.reverseBtn;
      }
      return this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyHoliday/getWorkhourRangebyDate',
        params: {
          beginTime: tableBeginTime,
          endTime: tableEndTime,
          isReturn: isReturn,  //用于验证 被退回状态下,日期重复问题
          pkOrg: this.form.pkOrg,
          id: this.form.id


        }
      });
    },
    //type 请假日期是否与开始结束日期相等 1请假日期=开始结束日期 2 请假日期=开始日期 ！=结束日期 3请假日期=结束日期！=开始
     getRealDays(applyStartTime, applyEndTime, hourRangeData, type) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间
        if (hourRangeData.length == 1) {
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            //请假结束大于下班时间
            if (endHours > 0) {
              return resultHours = ((hourRangeData[i].REAL_HOURS - startHours) / hourRangeData[i].REAL_HOURS).toFixed(1)
            } else {
              return resultHours = (this.getHour(applyStartTime, applyEndTime) / hourRangeData[i].REAL_HOURS).toFixed(1)
            }
          } else {
            //请假结束大于下班时间
            if (endHours > 0) {
              return resultHours = (this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME) / hourRangeData[i].REAL_HOURS).toFixed(1)
            } else {
              return resultHours = ((hourRangeData[i].REAL_HOURS - this.getHour(applyEndTime, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
            }
          }
        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            //请假结束大于下班时间
            if (endHours > 0) {
              resultHours += parseFloat(hourRangeData[i].REAL_HOURS - startHours)
            } else {
              resultHours += parseFloat(this.getHour(applyStartTime, applyEndTime))
            }
          } else {
            //请假结束大于下班时间
            if (endHours > 0) {
              resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME))
            } else {
              resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, applyEndTime))
            }
          }
          if(resultHours< 0){
            resultHours = 0
          }
          if (i === (hourRangeData.length - 1)) {
            this.totalHours +=resultHours;
            return (resultHours / totalHousr).toFixed(1)
          }


        }
      }

    },

    getRealDaysConstart(applyStartTime, applyEndTime, hourRangeData) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间
        if (hourRangeData.length == 1) {
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            return (parseFloat(this.getHour(applyStartTime, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
          } else {
            return (parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
          }
        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          //请假开始大于 开始上班时间
          if (startHours > 0) {
            resultHours += parseFloat(this.getHour(applyStartTime, hourRangeData[i].END_TIME));
          } else {
            resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME));
          }
          if(resultHours< 0){
            resultHours = 0
          }
          if (i === (hourRangeData.length - 1)) {
            this.totalHours +=resultHours;
            return (resultHours / totalHousr).toFixed(1)
          }

        }
      }

    },
    getRealDaysConstEnd(applyStartTime, applyEndTime, hourRangeData) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        //  var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间
        if (hourRangeData.length == 1) {
          //请假结束开始大于始下班时间
          if (endHours > 0) {
            return (parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)
          }//请假结束小于上班结束时间
          else {
            return (parseFloat(this.getHour(hourRangeData[i].START_TIME, applyEndTime)) / hourRangeData[i].REAL_HOURS).toFixed(1)
            // return ((parseFloat(hourRangeData[i].REAL_HOURS) - parseFloat(this.getHour(hourRangeData[i].START_TIME, applyEndTime))) / hourRangeData[i].REAL_HOURS).toFixed(1)
          }
        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          //请假开始大于 开始上班时间
          if (endHours > 0) {
            resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME));
          } else {
            resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, applyEndTime));
          }
          if(resultHours< 0){
            resultHours = 0
          }
          if (i === (hourRangeData.length - 1)) {
            this.totalHours +=resultHours;
            return (resultHours / totalHousr).toFixed(1)
          }
        }
      }

    },

    getRealDaysNotCont(applyStartTime, applyEndTime, hourRangeData) {
      if (hourRangeData.length == 0) {
        return 0.0;
      }
      var resultHours = 0;
      var totalHousr = 0;
      for (var i = 0; i < hourRangeData.length; i++) {
        var startHours = this.getHour(hourRangeData[i].START_TIME, applyStartTime)
        var endHours = this.getHour(hourRangeData[i].END_TIME, applyEndTime)
        //针对只有一个班时区间
        if (hourRangeData.length == 1) {
          return (parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME)) / hourRangeData[i].REAL_HOURS).toFixed(1)

        }
        //多区间计算
        else {
          // 计算总上班时间
          totalHousr += hourRangeData[i].REAL_HOURS;
          resultHours += parseFloat(this.getHour(hourRangeData[i].START_TIME, hourRangeData[i].END_TIME));

          if (i === (hourRangeData.length - 1)) {
            this.totalHours +=resultHours;
            return (resultHours / totalHousr).toFixed(1)
          }

        }
      }

    },
    getHour (s1, s2) {
      var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
      s1 = new Date((reDate.test(s1) ? s1 : '2020-1-1 ' + s1).replace(/-/g, '/'));
      s2 = new Date((reDate.test(s2) ? s2 : '2020-1-1 ' + s2).replace(/-/g, '/'));
      var ms = s2.getTime() - s1.getTime();
      if (ms < 0) return 0;
      return (ms / 1000 / 60 / 60).toFixed(2);
    },
    AndroisIos(){
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        this.isIOS=false
      } else if (isIOS) {
        this.isIOS=true
      }
    },
  },
  created () {
    this.form.id = this.$route.query.businessKey;
    this.AndroisIos();
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.form.id = this.EhrUtils.isEmpty(this.form.id) ? row['businessKey'] : this.form.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    this.form.pkDept = JSON.parse(localStorage.getItem('userInfo')).deptId;
    this.form.pkOrg = JSON.parse(localStorage.getItem('userInfo')).companyId;
    this.form.pkUser = JSON.parse(localStorage.getItem('userInfo')).id;
    let year = (new Date().getFullYear());
    let month = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
    let date = new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate();
    var timestamp = new Date().getTime().toString().substring(6, 12);
    var formNo = 'QJ' + year + timestamp;
    this.form.formNo = formNo;
    this.form.applyDate = year + '-' + month + '-' + date;
    this.getHolidayType();
    if (JSON.parse(sessionStorage.getItem('formData'))) {
      this.submitVisable1 = true;
    }
    let form = this.$route.params.form || JSON.parse(sessionStorage.getItem('formData')) || null;
    if (this.changeForm.pkHolidayChange) {
      form = this.changeForm;
    }
    sessionStorage.removeItem('formData');
    if (form) {
      this.form = form;
      this.getAdvanceApply(this.form.holidayType);
      if (form.formStatus === '0') {
        this.authEdit = false;
      } else {
        this.authEdit = true
      }
      if (this.$route.query.type) {
        this.authEdit = false;
      }
    } else if (this.form.id) {
      this.backFillEditData(true);
    };
  },

}
</script>
<style>
.van-picker.van-datetime-picker .van-hairline--top-bottom {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 9;
}
</style>
